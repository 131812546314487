import {
  Button,
  Switch,
  Popconfirm,
  Tooltip,
  Avatar,
  notification,
  Spin,
} from "antd";
import * as React from "react";
import { getAllUsers } from "../../Graphs/User/listUser";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  UserOutlined,
  EditOutlined,
  LockOutlined,
  CiCircleOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  WalletOutlined,
  TransactionOutlined,
  MinusCircleOutlined,
  UsergroupAddOutlined,
  UserAddOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { deleteUser } from "../../Graphs/User/deleteUser";
import { changeStatus } from "../../Graphs/User/changeStatus";
import { changeVerifyStatus } from "../../Graphs/User/changeVerifyStatus";
import { editUser } from "../../Graphs/User/editUser";
import { searchUser } from "../../Graphs/User/searchUser";
import { EditModal } from "./EditModal";
import { AddUserCoinModal } from "./AddUserCoinModal";
import UserCompanyTrn from "./UserCompanyTrn";
import UserCoinTrn from "./UserCoinTrn";
import { RemoveUserCoinModal } from "./RemoveUserCoinModal";
import { AssignDistributorModal } from "./AssignDistributorModal";
import { PasswordModal } from "./PasswordModal";
import { getDistributorUser } from "../../Graphs/distributor/getDistributorUser";
import { AddImmediateBalance } from "./AddImmediateBalance";
import { addMaximumInstantBalance } from "../../Graphs/balanceRequest/addMaximumInstantBalance";
import { AssignSalesModal } from "./AssignSalesModal";
import { alertError } from "../../utils/alert";
import TableWrap from "../common/Table";

var moment = require("moment");

const BASE_IMAGE_URL =
  "https://s3.us-east-2.amazonaws.com/cerebrus-consulting/bloodman_user_media/";

class UserRecordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getLimitedUsers = this.getLimitedUsers.bind(this);
  }

  async componentDidMount() {
    let permission = JSON.parse(this.props.loginUserData.access_permission);
    this.setState({
      userPermission: {
        edit:
          (permission && permission["user"] && permission["user"].edit) ||
          false,
        delete:
          (permission && permission["user"] && permission["user"].delete) ||
          false,
      },
    });

    this.setState({
      data: this.props.data,
      isLoading: false,
      userSelectedData: null,
      userTrasactionData: null,
    });
  }

  modalRef;
  transactionRef;
  userCompanyRef;
  modalUserCoinsRef;
  modalUserDebitCoinsRef;
  modalImmediateBalanceRef;
  modalDistributorAssignToUser;
  modalSalesAssignToUser;
  modalPasswordRef;

  async getLimitedUsers(pagination) {
    const { searchText } = this.props;
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText != null && searchText != "") {
          //get search text props from redux store
          this.listSearchUserData(start, end);
        } else {
          //call normal user Data
          this.listUserData(start, end);
        }
      }
    );
  }

  listSearchUserData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListUserData,
        setListUserDataTotal,
        searchText,
        loginUserData,
      } = this.props;
      this.setState({
        isLoading: true,
      });
      let city = loginUserData.role == "Sub_admin" ? loginUserData.city : "";

      let userData = await searchUser(authToken, searchText, start, end, city);

      if (userData.status == 200) {
        //set user Data
        await setListUserData(userData.data);
        await setListUserDataTotal(userData.total);

        this.setState({
          isLoading: false,
          data: userData.data,
        });
      } else if (userData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw userData && userData.message ? userData.message : "Error";
      }
    } catch (e) {
      alertError("USER", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  listUserData = async (start, end) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        setListUserData,
        setListUserDataTotal,
        loginUserData,
      } = this.props;
      this.setState({
        isLoading: true,
      });

      let role = this.props.loginUserData.role == "Sub_admin";
      let city = this.props.loginUserData.city;
      let userData =
        loginUserData.role == "Distributor"
          ? await getDistributorUser(authToken, start, end)
          : role
          ? await getAllUsers(authToken, start, end, city)
          : await getAllUsers(authToken, start, end);

      if (userData.status == 200) {
        //set user Data
        await setListUserData(userData.data);
        await setListUserDataTotal(userData.total);

        this.setState({
          isLoading: false,
          data: userData.data,
        });
      } else if (userData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw userData && userData.message ? userData.message : "Error";
      }
    } catch (e) {
      alertError("USER", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  dateFormat(date) {
    var oldDate = new Date(),
      momentObj = moment(oldDate).utc(),
      newDate = momentObj.add(2, "hours").format("Do MMM YYYY");
    return newDate;
  }

  showModal(item) {
    this.setState(
      {
        userSelectedData: item,
        // isOpen: true
      },
      () => {
        this.modalRef.show();
      }
    );
  }

  showChangePasswordModal(item) {
    this.setState(
      {
        userPasswordSelectedData: item,
        // isOpen: true
      },
      () => {
        this.modalPasswordRef.show();
      }
    );
  }

  showUserCompanyTrnsModal(item) {
    this.setState(
      {
        userData: item,
      },
      () => {
        this.userCompanyRef.show();
      }
    );
  }

  showUserCoinstModal(item, role) {
    this.setState(
      {
        userSelectedDataForCoin: { ...item, loginBy: role },
      },
      () => {
        this.modalUserCoinsRef.show();
      }
    );
  }

  showUserDebitCoinstModal(item) {
    this.setState(
      {
        userSelectedDataForDebitCoin: item,
        // isOpen: true
      },
      () => {
        this.modalUserDebitCoinsRef.show();
      }
    );
  }

  showImmediateBalanceModal(item) {
    this.setState(
      {
        userImmediateBalance: item,
        // isOpen: true
      },
      () => {
        this.modalImmediateBalanceRef.show();
      }
    );
  }

  assignDistributorToUser(item) {
    this.setState(
      {
        selectDataForAssignDistributor: item,
      },
      () => {
        this.modalDistributorAssignToUser.show();
      }
    );
  }

  assignSalsesToUser(item) {
    this.setState(
      {
        selectDataForAssignSales: item,
      },
      () => {
        this.modalSalesAssignToUser.show();
      }
    );
  }

  handleDetailsModalclose() {
    this.setState({
      userSelectedData: null,
      userPasswordSelectedData: null,
      userSelectedDataForCoin: null,
      userSelectedDataForDebitCoin: null,
      userImmediateBalance: null,
      selectDataForAssignDistributor: null,
      selectDataForAssignSales: null,
    });
  }

  handleUserCompanyTransactionModalclose() {
    this.setState({
      userData: null,
    });
  }

  showUserTransactions = (item) => {
    this.setState(
      {
        userTrasactionData: item,
      },
      () => {
        this.transactionRef.show();
      }
    );
  };

  handleDeleteTransactionModalclose() {
    this.setState({
      userTrasactionData: null,
    });
  }

  counter = 0;
  tempCounter = 0;
  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        );
      },
    },
    {
      title: "Name",
      width: 150,
      key: "name",
      render: (text, item, index) => {
        return (
          <div flexDirection="column" style={{ marginTop: "5px" }}>
            <strong>
              <div>
                {item.last_name
                  ? item.first_name
                    ? item.first_name + " " + item.last_name
                    : item.username
                  : item.first_name
                  ? item.first_name
                  : item.username}
              </div>
            </strong>
          </div>
        );
      },
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      render: (text, item, index) => {
        return <div>{item.email ? item.email : "-"}</div>;
      },
    },
    {
      title: "Shop Name",
      key: "shop",
      dataIndex: "shop",
      width: "150px",
      render: (text, item, index) => {
        return <div>{item.shop_name ? item.shop_name : "-"}</div>;
      },
    },
    {
      title: "Balance (LYD)",
      key: "wallet balance",
      width: "150px",
      render: (text, item, index) => {
        return (
          <div>
            {item.users_coins ? (
              item.users_coins.length > 0 ? (
                <span style={{ color: "blue" }}>
                  {item.users_coins[0].total_wallet_balance.toFixed(2)}
                </span>
              ) : (
                <span style={{ color: "blue" }}>-</span>
              )
            ) : (
              <span style={{ color: "blue" }}>-</span>
            )}
          </div>
        );
      },
    },

    {
      title: "Referal Code",
      key: "Referal Code",
      width: "150px",
      render: (text, item, index) => {
        return <div>{item.referal_code ? String(item.referal_code) : "0"}</div>;
      },
    },
    {
      title: "Mobile",
      key: "mobile",
      width: "150px",
      render: (text, item, index) => {
        return <div>{item.phone ? item.phone : "-"}</div>;
      },
    },
    {
      title: "Salesman Approval",
      key: "approved_status",
      width: "150px",
      render: (text, item, index) => {
        return (
          <div>
            {item.approved_status != "none" ? (
              item.approved_status == "approve" ? (
                <span
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    borderRadius: "15px",
                    padding: "5px 10px",
                  }}
                >
                  Approved
                </span>
              ) : item.approved_status == "pending" ? (
                <span
                  style={{
                    backgroundColor: "orange",
                    color: "white",
                    borderRadius: "15px",
                    padding: "5px 10px",
                  }}
                >
                  Pending
                </span>
              ) : (
                <span
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    borderRadius: "15px",
                    padding: "5px 10px",
                  }}
                >
                  Declined
                </span>
              )
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Under Salesman",
      key: "salesman_name",
      render: (text, item, index) => {
        return (
          <div>
            {item.salesman != null
              ? item.salesman.first_name + " " + item.salesman.last_name
              : "-"}
          </div>
        );
      },
    },
    {
      title: "City",
      key: "city",
      width: "150px",
      render: (text, item, index) => {
        return <div>{item.user_city != null ? item.user_city.name : "-"}</div>;
      },
    },
    {
      title: "Verify Status",
      key: "isVerified",
      render: (text, item, index) => {
        return (
          <div>
            {item.is_verified != null ? (
              <Switch
                checked={item.is_verified}
                style={{
                  backgroundColor: item.is_verified
                    ? this.props.primaryColor
                    : "#bababa",
                }}
                onChange={this.onVerifyChange.bind(this, item)}
              />
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: 150,
      key: "action",
      render: (text, item, index) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "10px",
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {this.props.loginUserData.role == "Admin" ||
            this.state.userPermission.edit ? (
              <div>
                <Tooltip placement="bottom" title="Edit">
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      console.log("isOpen value --- ", this.state.isOpen);
                      this.showModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}
            {this.props.loginUserData.role == "Admin" ||
            this.state.userPermission.edit ||
            this.props.loginUserData.role === "Sub_admin" ? (
              <div>
                <Tooltip placement="bottom" title="Change Password">
                  <Button
                    shape="circle"
                    icon={<LockOutlined />}
                    onClick={() => {
                      console.log("isOpen value --- ", this.state.isOpen);
                      this.showChangePasswordModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}

            <div>
              <Tooltip placement="bottom" title="Coin Transaction">
                <Button
                  shape="circle"
                  icon={<TransactionOutlined />}
                  onClick={() => {
                    console.log("isOpen value --- ", this.state.isOpen);
                    this.showUserTransactions(item);
                  }}
                />
              </Tooltip>
            </div>

            {this.props.loginUserData.role == "Admin" ||
            this.props.loginUserData.role == "Sub_admin" ||
            this.state.userPermission.delete ? (
              <div>
                <Tooltip placement="bottom" title="Delete">
                  <Popconfirm
                    onConfirm={this.deleteUser.bind(this, item)}
                    title="Are you sure you want to delete user？"
                  >
                    <Button shape="circle" icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Tooltip>
              </div>
            ) : null}
            {this.props.loginUserData.role == "Admin" && (
              <div>
                <Tooltip placement="bottom" title="Company Transaction">
                  <Button
                    shape="circle"
                    icon={<WalletOutlined />}
                    onClick={() => {
                      console.log("isOpen value --- ", this.state.isOpen);
                      this.showUserCompanyTrnsModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            )}
            {this.props.loginUserData.role == "Admin" ||
            this.props.loginUserData.role == "Distributor" ||
            this.props.loginUserData.role == "Sub_admin" ||
            this.state.userPermission.edit ? (
              <div>
                <Tooltip placement="bottom" title="Add Coin In Wallet">
                  <Button
                    shape="circle"
                    icon={<PlusCircleOutlined />}
                    onClick={() => {
                      this.showUserCoinstModal(
                        item,
                        this.props.loginUserData.role
                      );
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}
            {this.props.loginUserData.role == "Admin" ||
            this.props.loginUserData.role == "Sub_admin" ||
            this.state.userPermission.edit ? (
              <div>
                <Tooltip placement="bottom" title="Debit Amount In Wallet">
                  <Button
                    shape="circle"
                    icon={<MinusCircleOutlined />}
                    onClick={() => {
                      this.showUserDebitCoinstModal(item);
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}
            {this.props.loginUserData.role == "Admin" ||
            this.state.userPermission.edit ||
            this.props.loginUserData.role == "Sub_admin" ? (
              <div>
                <Tooltip placement="bottom" title="Assign Distributor">
                  <Button
                    shape="circle"
                    icon={<UserAddOutlined />}
                    onClick={() => {
                      this.assignDistributorToUser(item);
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}
            {this.props.loginUserData.role == "Admin" ||
            this.props.loginUserData.role == "Sub_admin" ||
            this.state.userPermission.edit ? (
              <div>
                <Tooltip
                  placement="bottom"
                  title="Maximum Amount For Immediate Balance"
                >
                  <Button
                    shape="circle"
                    icon={<DollarOutlined />}
                    onClick={() => {
                      this.showImmediateBalanceModal(
                        item,
                        this.props.loginUserData.role
                      );
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}
            {this.props.loginUserData.role == "Admin" ||
            this.props.loginUserData.role == "Sub_admin" ||
            this.state.userPermission.edit ? (
              <div>
                <Tooltip placement="bottom" title="Assign Sales">
                  <Button
                    shape="circle"
                    icon={<UsergroupAddOutlined />}
                    disabled={item.salesman}
                    onClick={() => {
                      this.assignSalsesToUser(item);
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];

  async deleteUser(item) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
        setListUserData,
        setListUserDataTotal,
        loginUserData,
      } = this.props;
      const { startIndex, limitNumber } = this.state;
      let cityId = loginUserData.role == "Sub_admin" ? loginUserData.city : "";

      this.setState({
        isLoading: true,
      });
      let deleteData = await deleteUser(
        authToken,
        item.id,
        startIndex,
        limitNumber,
        searchText,
        cityId
      );

      if (deleteData.status == 200) {
        notification["success"]({
          message: "Delete User",
          description: "Successfully deleted",
        });
        await setListUserData(deleteData.data);
        await setListUserDataTotal(deleteData.total);
        //set user Data
        this.setState({
          isLoading: false,
          data: deleteData.data,
        });
      } else if (deleteData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw deleteData && deleteData.message ? deleteData.message : "Error";
      }
    } catch (e) {
      alertError("USER", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onChange(item, checked) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeStatus(authToken, item.id, checked);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Change User Status",
          description: "Successfully changed status",
        });
        if (searchText != null && searchText != "") {
          this.listSearchUserData(startIndex, limitNumber);
          return;
        }
        this.listUserData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (err) {
      alertError("USER", err);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async onVerifyChange(item, checked) {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      this.setState({
        isLoading: true,
      });
      let statusData = await changeVerifyStatus(authToken, item.id, checked);
      if (statusData.status == 200) {
        notification["success"]({
          message: "Change User Verify Status",
          description: "Successfully changed status",
        });
        if (searchText != null && searchText != "") {
          this.listSearchUserData(startIndex, limitNumber);
          return;
        }
        this.listUserData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (err) {
      alertError("USER", err);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  editSubmit = async (userObject, userId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
        loginUserData,
      } = this.props;
      const { startIndex, limitNumber } = this.state;
      this.setState({
        isLoading: true,
      });
      let cityId = loginUserData.role == "Sub_admin" ? loginUserData.city : "";
      let body;
      if (cityId) {
        body = { ...userObject, cityId };
      } else {
        body = userObject;
      }
      let statusData = await editUser(authToken, body, userId);

      if (statusData.status == 200) {
        notification["success"]({
          message: "Edit User Status",
          description: "Successfully edited",
        });
        if (searchText != null && searchText != "") {
          this.listSearchUserData(startIndex, limitNumber);
          return;
        }
        this.listUserData(startIndex, limitNumber);
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      alertError("User", e);
    }
  };

  addImmediateBalance = async (values, userId) => {
    try {
      const {
        authToken,
        history,
        setLoginFlag,
        setUserData,
        setUserToken,
        searchText,
      } = this.props;
      const { startIndex, limitNumber } = this.state;

      const body = JSON.stringify({ max_instant_balance: values.balance });

      this.setState({
        isLoading: true,
      });
      const user_id = userId;
      let statusData = await addMaximumInstantBalance(authToken, user_id, body);
      if (statusData.status == 200) {
        this.setState({
          isLoading: false,
        });
        notification["success"]({
          message: "Edit User Status",
          description: "Successfully edited",
        });
      } else if (statusData.status == 401) {
        await setLoginFlag(false);
        await setUserData(null);
        await setUserToken(null);
        history.push(`${process.env.PUBLIC_URL}/login`);
      } else {
        throw statusData && statusData.message ? statusData.message : "Error";
      }
    } catch (e) {
      alertError("USER", e);
      this.setState({
        isLoading: false,
      });
    }
  };

  handleTableChange(pagination, filters) {
    console.log("outer onchange iusers", pagination);
    this.setState(
      {
        pagination,
      },
      () => this.getLimitedUsers(pagination)
    );
  }

  render() {
    const { isLoading } = this.state;
    const { listUserData, listUserDataTotal, searchLoader, authToken } =
      this.props;

    return isLoading || searchLoader ? (
      <div style={{ marginLeft: "20px" }}>
        <Spin
          size="large"
          style={{ marginLeft: "480px", marginTop: "130px" }}
        />
      </div>
    ) : (
      <div style={{ "overflow-x": "auto" }}>
        <TableWrap
          data={listUserData}
          columns={this.columns}
          isLoading={this.state.isLoading}
          total={listUserDataTotal}
          pagination={this.state.pagination}
          handleTableChange={this.handleTableChange}
          scroll={{ x: 1700 }}
        />

        {this.state.userSelectedData ? (
          <EditModal
            admin={false}
            refx={(e) => (this.modalRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            userSelectedData={this.state.userSelectedData}
            onSubmit={this.editSubmit}
            authToken={this.props.authToken}
          />
        ) : null}
        {this.state.userPasswordSelectedData ? (
          <PasswordModal
            admin={false}
            refx={(e) => (this.modalPasswordRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            userPasswordSelectedData={this.state.userPasswordSelectedData}
            onSubmit={this.editSubmit}
          />
        ) : null}

        {this.state.userTrasactionData ? (
          <UserCoinTrn
            admin={false}
            refx={(e) => (this.transactionRef = e)}
            onClose={this.handleDeleteTransactionModalclose.bind(this)}
            userTrasactionData={this.state.userTrasactionData}
            authToken={authToken}
          />
        ) : null}

        {this.state.userSelectedDataForCoin ? (
          <AddUserCoinModal
            admin={false}
            authToken={this.props.authToken}
            refx={(e) => (this.modalUserCoinsRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            userSelectedData={this.state.userSelectedDataForCoin}
            onSubmit={this.editSubmit}
            reload={this.listSearchUserData}
            setDistributorBalance={this.props.setDistributorBalance}
          />
        ) : null}
        {this.state.userSelectedDataForDebitCoin ? (
          <RemoveUserCoinModal
            admin={false}
            authToken={this.props.authToken}
            refx={(e) => (this.modalUserDebitCoinsRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            userSelectedData={this.state.userSelectedDataForDebitCoin}
            onSubmit={this.editSubmit}
          />
        ) : null}
        {this.state.userImmediateBalance ? (
          <AddImmediateBalance
            admin={false}
            authToken={this.props.authToken}
            refx={(e) => (this.modalImmediateBalanceRef = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            userSelectedData={this.state.userImmediateBalance}
            onSubmit={this.addImmediateBalance}
          />
        ) : null}

        {this.state.selectDataForAssignDistributor ? (
          <AssignDistributorModal
            admin={false}
            authToken={this.props.authToken}
            refx={(e) => (this.modalDistributorAssignToUser = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            SelectedData={this.state.selectDataForAssignDistributor}
            onSubmit={this.editSubmit}
            loginUserData={this.props.loginUserData}
          />
        ) : null}

        {this.state.selectDataForAssignSales ? (
          <AssignSalesModal
            admin={false}
            authToken={this.props.authToken}
            refx={(e) => (this.modalSalesAssignToUser = e)}
            onClose={this.handleDetailsModalclose.bind(this)}
            SelectedData={this.state.selectDataForAssignSales}
            loginUserData={this.props.loginUserData}
            onSubmit={this.editSubmit}
            reload={(pagination) => {
              this.listUserData(0, 10);
              this.setState({
                startIndex: 0,
                limitNumber: 10,
              });
            }}
          />
        ) : null}

        {this.state.userData ? (
          <UserCompanyTrn
            admin={false}
            refx={(e) => (this.userCompanyRef = e)}
            onClose={this.handleUserCompanyTransactionModalclose.bind(this)}
            userData={this.state.userData}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  listUserData: state.user.listUserData,
  listUserDataTotal: state.user.listUserDataTotal,
  searchLoader: state.auth.searchLoader,
  searchText: state.auth.searchText,
  loginUserData: state.auth.loginUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginFlag: (flag) => {
      dispatch({
        type: "SET_LOGIN_FLAG",
        flag: flag,
      });
    },
    setUserData: (userData) => {
      dispatch({
        type: "SET_USER_DATA",
        userData: userData,
      });
    },
    setUserToken: (authToken) => {
      dispatch({
        type: "SET_USER_AUTHTOKEN",
        authToken: authToken,
      });
    },
    setListUserData: (listUserData) => {
      dispatch({
        type: "SET_LIST_USER_DATA",
        listUserData: listUserData,
      });
    },
    setListUserDataTotal: (listUserDataTotal) => {
      dispatch({
        type: "SET_LIST_USER_DATA_TOTAL",
        listUserDataTotal: listUserDataTotal,
      });
    },
    setDistributorBalance: (distributorBalance) => {
      dispatch({
        type: "SET_DISTRIBUTOR_BALANCE",
        distributorBalance: distributorBalance,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserRecordsTable));
