import { Button, DatePicker, notification, Table } from "antd";
import { Formik } from "formik";
import moment from "moment";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { alertError } from "../../utils/alert";
import { connect } from "react-redux";
import { dateFormat, dateTimeFormat, formatePrice } from "../../utils/general";
import TableWrap from "../common/Table";
import { reportExpensesAPI } from "../../Graphs/expenses/ReportExpenses";
const { RangePicker } = DatePicker;

class ExpensesReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ExpensesList: [],
      startDate: "",
      endDate: "",
      allExpensesData: null,
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.getExpensesReportList = this.getExpensesReportList.bind(this);
    this.updatePagination = this.updatePagination.bind(this);
  }

  resetExpensesReportTable = () => {
    this.setState({
      ExpensesList: [],
    });
  };

  onChangeDate = (date) => {
    if (!date || !date.length) {
      this.resetExpensesReportTable();
      this.setState({
        startDate: null,
        endDate: null,
      });
    }
    this.setState({
      startDate: date?.[0]?.format("YYYY-MM-DD"),
      endDate: date?.[1]?.format("YYYY-MM-DD"),
    });
  };

  getExpensesReportList = async (page, limit) => {
    try {
      const { authToken, loginUserData } = this.props;
      let city = loginUserData.role == "Sub_admin" ? loginUserData.city : "";

      let payload = {
        start_date: this.state.startDate,
        end_date: this.state.endDate,
        city: city
      };

      let expensesReportList = await reportExpensesAPI(
        authToken,
        payload,
        page,
        limit
      );
      this.setState({
        isLoading: true,
      });
      if (expensesReportList.status == 200) {
        this.setState({
          ExpensesList: expensesReportList.data,
          allExpensesData: expensesReportList,
          isLoading: false,
        });
      } else {
        throw expensesReportList && expensesReportList.message
          ? expensesReportList.message
          : "Error";
      }
    } catch (e) {
      alertError("REPORT", e);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  async updatePagination(pagination) {
    const start =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const end = pagination.pageSize;

    this.setState({
      isLoading: true,
      startIndex: start,
      limitNumber: end,
    });
    this.getExpensesReportList(start, end);
  }

  handleTableChange(pagination) {
    this.setState(
      {
        pagination,
      },
      () => this.updatePagination(pagination)
    );
  }

  columns = [
    {
      title: "Sr. No.",
      key: "serial_number",
      width: "150px",
      fixed: true,
      render: (text, item, index) => {
        return (
          <div>
            <span>{++index}</span>
          </div>
        );
      },
    },
    {
      title: "Description",
      key: "description",
      render: (text, item, index) => {
        return (
          <div>
            <span>{item.description}</span>
          </div>
        );
      },
    },
    {
      title: "Date",
      key: "date",
      render: (text, item, index) => {
        return (
          <div>
            <span>{dateTimeFormat(item.expenses_date)}</span>
          </div>
        );
      },
    },
    {
      title: "Amount",
      key: "amount",
      render: (text, item, index) => {
        return (
          <div>
            <span>{formatePrice(item.amount)}</span>
          </div>
        );
      },
    },
  ];

  render() {
    const { allExpensesData } = this.state;
    return (
      <>
        <Formik
          initialValues={{
            date: "",
          }}
          onSubmit={() => {
            let page = 0;
            let limit = 10;
            this.getExpensesReportList(page, limit);
          }}
        >
          {({ values, handleSubmit }) => {
            return (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  width: "100%",
                  marginBottom: "20px",
                  paddingRight: "55px",
                  justifyContent: "left",
                }}
              >
                <div style={{ cursor: "pointer" }}>
                  <RangePicker
                    format="YYYY-MM-DD"
                    onChange={this.onChangeDate}
                    disabledDate={(current) => {
                      return moment().add(0, "days") <= current;
                    }}
                  />
                </div>

                <div style={{ cursor: "pointer", width: "100%" }}>
                  <Button
                    style={{ background: "#5C6AC4", color: "#fff" }}
                    color={this.state.primaryColor}
                    onClick={handleSubmit}
                    disabled={this.state.startDate == ""}
                  >
                    Filter
                  </Button>
                </div>
              </div>
            );
          }}
        </Formik>
        {this.state.startDate && (
          <TableWrap
            data={this.state.ExpensesList}
            columns={this.columns}
            isLoading={this.state.isLoading}
            total={allExpensesData?.total}
            pagination={this.state.pagination}
            handleTableChange={this.handleTableChange}
            // scroll={{ x: 600 }}
            footer={(pageData) => {
              return (
                allExpensesData && (
                  <>
                    <Table.Summary.Row style={{ fontWeight: 700 }}>
                      <Table.Summary.Cell style={{ fontWeight: 700 }}>
                        Total Amount
                      </Table.Summary.Cell>
                      <Table.Summary.Cell style={{ fontWeight: 700 }}>
                        -
                      </Table.Summary.Cell>
                      <Table.Summary.Cell style={{ fontWeight: 700 }}>
                        -
                      </Table.Summary.Cell>
                      <Table.Summary.Cell style={{ fontWeight: 700 }}>
                        {/* {formatePrice(allExpensesData?.total_amount)} */}
                        {formatePrice(allExpensesData.total_amount > 0 ? allExpensesData.total_amount : 0 )}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                )
              );
            }}
          />
         )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.auth.authToken,
  loginUserData: state.auth.loginUserData,
});

export default connect(mapStateToProps)(withRouter(ExpensesReport));
